/**
 * CardImageText
 */

import React from 'react';
import Button from 'Components/Button';
import Wysiwyg from 'Components/Wysiwyg';
import classNames from 'classnames';
import BackgroundImage from 'Components/BackgroundImage';
import s from './CardImageText.module.scss';

const CardImageText = ({
    title = '',
    text = '',
    link,
    image = {},
    reverse,
    contain,
    choice,
}) => {
    const hasContent = Boolean(title || text);

    const wide = choice && choice === 'wide';
    const increaseImageSize = choice && choice === 'increaseImageSize';
    const slimmer = choice && choice === 'slim';

    const classes = classNames(
        s['CardImageText'],
        {[s['CardImageText--Reverse']]: reverse },
        {[s['CardImageText--Contain']]: contain },
        {[s['CardImageText--IncreaseImageSize']]: increaseImageSize },
        {[s['CardImageText--Slim']]: slimmer},
        {[s['CardImageText--Horizontal']]: !wide },
        {[s['CardImageText--Wide']]: wide || !hasContent },
    );

    return (
        <article className={classes}>
            {hasContent && wide && (
                <div className={s['CardImageText__Header']}>
                    {title && <h2 className={s['CardImageText__Title']}>{title}</h2>}
                </div>
            )}
            <div className={s['CardImageText__ImageWrapper']}>
                <BackgroundImage
                    image={image}
                    className={s['CardImageText__Image']}
                />
            </div>

            {hasContent && !wide && (
                <div className={s['CardImageText__Content']}>
                    {!!title && (
                        <h3 className={s['CardImageText__Title']}>{title}</h3>
                    )}
                    {!!text && (
                        <div className={s['CardImageText__Text']}>
                            <Wysiwyg modifier="Small" wysiwyg={text}/>
                        </div>
                    )}
                    {link && link.url && link.text && (
                        <Button href={link.url} text={link.text} />
                    )}
                </div>
            )}
        </article>
    );
};

export default CardImageText;
